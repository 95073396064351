





























































































import Banner from "@/models/banner";
import BannerStatus from "@/models/bannerStatus";
import User from "@/models/user";
import { useBannerStore } from "@/store/banner";
import { useUserStore } from "@/store/user";
import { defineComponent } from "@vue/composition-api";
import NullableField from "../NullableField.vue";

interface TableOptions {
  groupBy: never[];
  groupDesc: never[];
  multiSort: boolean;
  mustSort: boolean;
  sortDesc: never[];
  page: number;
  itemsPerPage: number;
  sortBy: never[];
}

export default defineComponent({
  name: "BannerTable",
  components: {
    NullableField,
  },
  setup() {
    const bannerStore = useBannerStore();
    const userStore = useUserStore();
    return {
      bannerStore,
      userStore,
    };
  },
  props: {
    headers: {
      default: () => [],
      type: Array,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Object,
    },
    totalBanners: {
      default: 0,
      type: Number,
    },
    banners: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100],
      },
      tableOptions: {
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      } as TableOptions,
    };
  },
  watch: {
    options(val: TableOptions, _oldVal: TableOptions): void {
      Object.assign(this.tableOptions, val);
    },
    tableOptions(val: TableOptions, _oldVal: TableOptions): void {
      if (val === this.options) {
        return;
      }
      this.$emit("update:options", this.tableOptions);
    },
  },
  mounted() {
    Object.assign(this.tableOptions, this.options);
  },
  methods: {
    beginEditing(banner: Banner): void {
      this.$emit("edit", banner);
    },

    confirmDelete(banner: Banner): void {
      this.$emit("confirm-delete", banner);
    },

    didClickRow(banner: Banner): void {
      if (!banner.id) {
        return;
      }
      this.bannerStore.fetchById(banner.id);
      this.$router.push(`/banners/${banner.id}`);
    },

    hasActionOptions(item: Banner): boolean {
      return this.validateStatus(this.userStore.isAdmin, item.status);
    },

    iconSrc(iconName: string): string {
      if (this.$vuetify.theme.dark) {
        return require(`../../assets/icons/${iconName}-w.png`);
      } else {
        return require(`../../assets/icons/${iconName}.png`);
      }
    },

    initials(user: User): string {
      if (!user.name) {
        return "?";
      }
      const parts = user.name.split(" ");
      const first = parts[0] ? parts[0].charAt(0) : "";
      const last = parts[1] ? parts[1].charAt(0) : "";
      return `${first}${last}`;
    },

    status(status: BannerStatus): string {
      switch (status) {
        case BannerStatus.SCHEDULED:
          return "Scheduled";
        case BannerStatus.PUBLISHED:
          return "Published";
        case BannerStatus.UNPUBLISHED:
          return "Unpublished";
        default:
          return "warning";
      }
    },

    statusIcon(status: BannerStatus): string {
      switch (status) {
        case BannerStatus.SCHEDULED:
          return "clock";
        case BannerStatus.PUBLISHED:
          return "check-circle";
        case BannerStatus.UNPUBLISHED:
          return "alert-circle";
        default:
          return "warning";
      }
    },

    validateStatus(admin: boolean, status: BannerStatus): boolean {
      return admin || status === BannerStatus.UNPUBLISHED;
    },

    tooltip(user: User): string {
      return `${user.name} (${user.email})`;
    },

    didSelectRow(banner: Banner): void {
      this.userStore.isAdmin || banner.status === BannerStatus.UNPUBLISHED
        ? this.$emit("edit", banner)
        : this.$emit("select", banner);
    },
  },
});
