import ProfilePayload from "../../models/profilePayload";
import Response from "../../models/response";
import { Role } from "../../models/user";
import User from "../../models/user";
import UserApi from "@/api/user";
import { defineStore } from "pinia";

interface UserState {
  isLoading: boolean;
  profile: User | null;
}

export const useUserStore = defineStore({
  id: "user",
  state: (): UserState => {
    return {
      isLoading: false,
      profile: null,
    };
  },
  getters: {
    getProfile(state: UserState): User | null {
      return state.profile;
    },

    isAdmin(state: UserState): boolean {
      if (!state.profile) {
        return false;
      }
      return state.profile.roles.includes(Role.ADMIN);
    },
  },
  actions: {
    fetchProfile(): Promise<Response<ProfilePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        UserApi.getProfile()
          .then((resp: Response<ProfilePayload>) => {
            if (!resp.payload || !resp.payload.profile) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.profile = resp.payload.profile;
            resolve(resp);
          })
          .catch((resp: Response<ProfilePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
  },
});
