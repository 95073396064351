import Brand from "../../models/brand";
import DeviceType from "../../models/deviceType";
import Language from "../../models/language";
import Region from "../../models/region";

export class FetchCustomersQueryContext {
  appVersion: string[] = [];
  brand: Brand[] = [];
  customerGuid: string[] = [];
  deviceType: DeviceType[] = [];
  deviceVersion: string[] = [];
  generation: string[] = [];
  language: Language | null = null;
  limit = 20;
  modelCode: string[] = [];
  modelYear: string[] = [];
  offset = 0;
  region: Region[] = [];
  subscription: string[] = [];
  vin: string[] = [];
  sortBy: string[] = [];
  sortDir: string[] = [];
}
