var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.banners,"loading":_vm.isLoading,"multi-sort":true,"options":_vm.tableOptions,"server-items-length":_vm.totalBanners,"no-data-text":"No banners found."},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.didSelectRow},scopedSlots:_vm._u([{key:"header.referenceNumber",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.referenceNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.referenceNumber))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.createdBy)?_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary white--text","size":"36"}},'v-avatar',attrs,false),on),[_c('strong',[_vm._v(_vm._s(_vm.initials(item.createdBy)))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip(item.createdBy))+" ")])]):_vm._e(),_c('NullableField',{attrs:{"type":"datetime"},model:{value:(item.createdAt),callback:function ($$v) {_vm.$set(item, "createdAt", $$v)},expression:"item.createdAt"}})]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"display":"inline-block","margin-bottom":"-3px"},attrs:{"width":"10%","alt":_vm.status(item.status),"src":_vm.iconSrc(_vm.statusIcon(item.status))}}),_vm._v(" "+_vm._s(_vm.status(item.status))+" ")]}},{key:"header.startsAt",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.startsAt",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{attrs:{"type":"datetime"},model:{value:(item.startsAt),callback:function ($$v) {_vm.$set(item, "startsAt", $$v)},expression:"item.startsAt"}})]}},{key:"header.endsAt",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.endsAt",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{attrs:{"type":"datetime"},model:{value:(item.endsAt),callback:function ($$v) {_vm.$set(item, "endsAt", $$v)},expression:"item.endsAt"}})]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasActionOptions(item))?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[(_vm.validateStatus(_vm.userStore.isAdmin, item.status))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }