import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { FetchBannersQueryContext } from "./dto/fetchBannersQueryContext";
import BannerPagePayload from "../models/bannerPagePayload";
import BannerPayload from "../models/bannerPayload";
import BannerProperties from "../models/bannerProperties";
import qs from "querystring";
import Response from "../models/response";
import router from "../router";
import Util from "./util";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  all(params: FetchBannersQueryContext): Promise<Response<BannerPagePayload>> {
    const url = Util.apiUrl("/v1/banners");

    return axios
      .get<Response<BannerPagePayload>>(url, {
        headers: Util.authHeaders(),
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((resp: AxiosResponse<Response<BannerPagePayload>>) =>
        Util.handleResponse<BannerPagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<BannerPagePayload>>) =>
        Util.handleError<BannerPagePayload>(error)
      );
  },

  create(properties: BannerProperties): Promise<Response<BannerPayload>> {
    const url = Util.apiUrl(`/v1/banners`);

    return axios
      .post<Response<BannerPayload>>(url, properties, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<BannerPayload>>) =>
        Util.handleResponse<BannerPayload>(resp.data)
      )
      .catch((error: AxiosError<Response<BannerPayload>>) =>
        Util.handleError<BannerPayload>(error)
      );
  },

  delete(id: string): Promise<Response<BannerPayload>> {
    const url = Util.apiUrl(`/v1/banners/${id}`);

    return axios
      .delete<Response<BannerPayload>>(url, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<BannerPayload>>) =>
        Util.handleResponse<BannerPayload>(resp.data)
      )
      .catch((error: AxiosError<Response<BannerPayload>>) =>
        Util.handleError<BannerPayload>(error)
      );
  },

  getById(id: string): Promise<Response<BannerPayload>> {
    const url = Util.apiUrl(`/v1/banners/${id}`);

    return axios
      .get<Response<BannerPayload>>(url, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<BannerPayload>>) =>
        Util.handleResponse<BannerPayload>(resp.data)
      )
      .catch((error: AxiosError<Response<BannerPayload>>) =>
        Util.handleError<BannerPayload>(error)
      );
  },

  update(
    id: string,
    properties: BannerProperties
  ): Promise<Response<BannerPayload>> {
    const url = Util.apiUrl(`/v1/banners/${id}`);

    return axios
      .put<Response<BannerPayload>>(url, properties, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<BannerPayload>>) =>
        Util.handleResponse<BannerPayload>(resp.data)
      )
      .catch((error: AxiosError<Response<BannerPayload>>) =>
        Util.handleError<BannerPayload>(error)
      );
  },
};
