export default class StatusMessage {
  description: string | null;
  detailedDescription: string | null;
  responseCode: string | null;

  constructor(json: Record<string, unknown>) {
    this.description = (json.description as string) ?? null;
    this.detailedDescription = (json.detailedDescription as string) ?? null;
    this.responseCode = (json.responseCode as string) ?? null;
  }
}
