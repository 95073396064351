import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { FetchFiltersQueryContext } from "./dto/fetchFiltersQueryContext";
import qs from "querystring";
import Response from "../models/response";
import router from "../router";
import Util from "./util";
import FilterPagePayload from "@/models/filterPagePayload";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  all(
    filterName: string,
    params: FetchFiltersQueryContext
  ): Promise<Response<FilterPagePayload>> {
    const url = Util.apiUrl(`/v1/filters/${filterName}`);

    return axios
      .get<Response<FilterPagePayload>>(url, {
        headers: Util.authHeaders(),
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((resp: AxiosResponse<Response<FilterPagePayload>>) =>
        Util.handleResponse<FilterPagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<FilterPagePayload>>) =>
        Util.handleError<FilterPagePayload>(error)
      );
  },
};
