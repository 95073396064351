import DeviceType from "./deviceType";
import Brand from "./brand";
import Language from "./language";
import Region from "./region";
import User from "./user";

export enum SubscriptionType {
  REMOTE = "PROD_REMOTESERVICE",
  EV_REMOTE = "PROD_EREMOTE",
  TM_REMOTE = "PROD_TMREMOTESERVICE",
  EV_TM_REMOTE = "PROD_TMEREMOTE",
  REMOTE_V2 = "PROD_REMOTEV2",
  REMOTE_LITE_MECHANICAL = "PROD_REMOTELM",
  REMOTE_LITE_SMART = "PROD_REMOTELS",
  REMOTE_DIGITAL_KEY = "PROD_REMKEY1",
  REMOTE_V2_DIGITAL_KEY = "PROD_REMKEY2",
  EV_REMOTE_DIGITAL_KEY = "PROD_EREMKEY",
  DYNAMIC_NAVIGATION = "PROD_NAVIGATION",
  DESTNATION_ASSIST = "PROD_DESTASSIST",
  SERVICE_CONNECT = "SERVICE_CONNECT",
}

export enum Status {
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  SENT = "SENT",
}

export default class Notification {
  approvedAt: string | null;
  approvedBy: User | null;
  appVersions: string[];
  brands: Brand[];
  createdAt: string | null;
  createdBy: User | null;
  customerGuids: string[];
  delivered: number | null;
  deviceTypes: DeviceType[];
  deviceVersions: string[];
  dryRun: boolean;
  failed: number | null;
  generations: string[];
  id: string | null;
  language: Language | null;
  messageBody: string | null;
  messageTitle: string | null;
  modelCodes: string[];
  modelYears: string[];
  name: string | null;
  notes: string | null;
  pending: number | null;
  referenceNumber: string | null;
  regions: Region[];
  subscriptions: string[];
  updatedAt: string | null;
  vins: string[];

  constructor() {
    this.approvedAt = null;
    this.approvedBy = null;
    this.appVersions = [];
    this.brands = [];
    this.createdAt = null;
    this.createdBy = null;
    this.customerGuids = [];
    this.delivered = null;
    this.dryRun = false;
    this.deviceTypes = [];
    this.deviceVersions = [];
    this.failed = null;
    this.generations = [];
    this.id = null;
    this.language = Language.ENGLISH;
    this.messageBody = null;
    this.messageTitle = null;
    this.modelCodes = [];
    this.modelYears = [];
    this.name = null;
    this.notes = null;
    this.pending = null;
    this.referenceNumber = null;
    this.regions = [];
    this.subscriptions = [];
    this.updatedAt = null;
    this.vins = [];
  }

  get status(): Status {
    if (!this.approvedAt) {
      return Status.PENDING;
    }

    return (this.pending ?? 0) > 0 ? Status.SENT : Status.IN_PROGRESS;
  }

  static fromJSON(json: Record<string, unknown>): Notification {
    const notification = new Notification();

    notification.approvedAt = (json.approvedAt as string) ?? null;
    notification.approvedBy = User.fromJSON(
      json.approvedBy as Record<string, unknown>
    );
    notification.appVersions = (json.appVersions as string[]) ?? [];
    notification.brands = (json.brands as Brand[]) ?? null;
    notification.createdAt = (json.createdAt as string) ?? null;
    notification.createdBy = User.fromJSON(
      json.createdBy as Record<string, unknown>
    );
    notification.customerGuids = (json.customerGuids as string[]) ?? [];
    notification.delivered = (json.delivered as number) ?? null;
    notification.deviceTypes = (json.deviceTypes as DeviceType[]) ?? [];
    notification.deviceVersions = (json.deviceVersions as string[]) ?? [];
    notification.dryRun = (json.dryRun as boolean) ?? false;
    notification.failed = (json.failed as number) ?? null;
    notification.generations = (json.generations as string[]) ?? [];
    notification.id = json.id as string;
    notification.language = (json.language as Language) ?? null;
    notification.messageBody = (json.messageBody as string) ?? null;
    notification.messageTitle = (json.messageTitle as string) ?? null;
    notification.modelCodes = (json.modelCodes as string[]) ?? [];
    notification.modelYears = (json.modelYears as string[]) ?? [];
    notification.name = (json.name as string) ?? null;
    notification.notes = (json.notes as string) ?? null;
    notification.pending = (json.pending as number) ?? null;
    notification.referenceNumber = (json.referenceNumber as string) ?? null;
    notification.regions = (json.regions as Region[]) ?? [];
    notification.subscriptions = (json.subscriptions as string[]) ?? [];
    notification.updatedAt = (json.updatedAt as string) ?? null;
    notification.vins = (json.vins as string[]) ?? [];

    return notification;
  }
}
