var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('v-card-text',{staticStyle:{"margin-left":"10px","padding":"40px"}},[_c('v-row',[_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.customerStore.isLoading),expression:"customerStore.isLoading"}]},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1),_c('v-col',{staticClass:"background",staticStyle:{"padding-right":"60px"},attrs:{"cols":8}},[_c('p',{staticClass:"text-h4 text--primary font-weight-bold title",domProps:{"textContent":_vm._s(_vm.cardTitle)}}),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Name")]),_c('ValidationProvider',{ref:"name",attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('v-text-field',{attrs:{"disabled":_vm.isViewing,"error-messages":errors,"success":valid,"flat":"","outlined":"","placeholder":"Remote Services System Migration","solo":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Template")]),_c('v-autocomplete',{staticClass:"no-translate",attrs:{"search-input":_vm.query,"items":_vm.templates,"item-text":_vm.formatTemplateName,"color":"grey","outlined":"","clearable":"","hide-no-data":"","placeholder":"Search","append-icon":"mdi-magnify","return-object":""},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event}},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}})],1),_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Feature")]),_c('ValidationProvider',{ref:"feature",attrs:{"name":"Feature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('v-select',{attrs:{"items":_vm.features,"item-text":function (item) { return item.displayName; },"disabled":_vm.isViewing,"error-messages":errors,"success":valid,"outlined":"","solo":"","flat":"","label":"Select feature"},model:{value:(_vm.feature),callback:function ($$v) {_vm.feature=$$v},expression:"feature"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v(" Message Title ")]),_c('v-text-field',{attrs:{"disabled":_vm.isViewing,"flat":"","outlined":"","placeholder":"Remote Services System Migration Announcement","solo":""},model:{value:(_vm.messageTitle),callback:function ($$v) {_vm.messageTitle=$$v},expression:"messageTitle"}})],1)],1),_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Message Body")]),_c('ValidationProvider',{ref:"messagebody",attrs:{"name":"Message Body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('v-textarea',{attrs:{"rows":_vm.textAreaRows,"disabled":_vm.isViewing,"error-messages":errors,"success":valid,"flat":"","outlined":"","required":"","placeholder":"Write Banner Message...","solo":""},model:{value:(_vm.messageBody),callback:function ($$v) {_vm.messageBody=$$v},expression:"messageBody"}})}}],null,true)}),_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v(" Internal Notes ")]),_c('v-textarea',{attrs:{"rows":3,"disabled":_vm.isViewing,"solo":"","flat":"","placeholder":"Internal Notes","outlined":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"disabled":!_vm.userStore.isAdmin || _vm.isViewing,"row":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('v-radio',{attrs:{"value":"UNPUBLISHED","label":"Unpublished","color":"black"}}),_c('v-radio',{attrs:{"value":"PUBLISHED","label":"Published","color":"black"}}),_c('v-radio',{attrs:{"value":"SCHEDULED","label":"Scheduled","color":"black"}})],1)],1)],1),(_vm.showSchedulePickers)?_c('v-row',{staticStyle:{"margin-top":"-10px","margin-bottom":"10px"}},[_c('v-col',[_c('span',[_vm._v("Start Date")]),_c('ValidationProvider',{attrs:{"vid":"startsAt","rules":{
                    required: !_vm.endsAt || _vm.endsAt.length === 0,
                  },"mode":"lazy","name":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('datetime',{staticClass:"theme-red",attrs:{"disabled":_vm.isViewing,"minute-step":15,"input-class":"input-date","type":"datetime","required":"","use12-hour":""},model:{value:(_vm.startsAt),callback:function ($$v) {_vm.startsAt=$$v},expression:"startsAt"}}),(errors.length > 0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-col',[_c('span',[_vm._v("End Date")]),_c('ValidationProvider',{attrs:{"vid":"endsAt","rules":{
                    required: !_vm.startsAt || _vm.startsAt.length === 0,
                  },"mode":"lazy","name":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('datetime',{staticClass:"theme-red",attrs:{"disabled":_vm.isViewing,"error-messages":errors,"minute-step":15,"input-class":"input-date","type":"datetime","use12-hour":""},model:{value:(_vm.endsAt),callback:function ($$v) {_vm.endsAt=$$v},expression:"endsAt"}}),(errors.length > 0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-col')],1):_vm._e(),_c('v-row',[_c('v-col',[(!_vm.isViewing)?_c('v-btn',{staticClass:"text-none font-weight-bold elevation-0",staticStyle:{"margin-right":"30px"},attrs:{"disabled":!_vm.hasCustomers,"type":"submit","width":"170px","height":"50px","color":"primary","large":""}},[_vm._v(" Save ")]):_vm._e(),(!_vm.isViewing)?_c('v-btn',{staticClass:"text-none font-weight-bold elevation-0",attrs:{"large":"","outlined":"","width":"170px","height":"50px","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]):_vm._e(),_c('strong',{staticClass:"float-right text--primary",staticStyle:{"margin-top":"20px"}},[_vm._v(_vm._s(_vm.customerStore.total.toLocaleString())+" Customers Selected ")])],1)],1)],1),_c('CustomerFiltersPanel',{attrs:{"mode":_vm.mode,"visible":_vm.visible},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }