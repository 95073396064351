







































































































import { defineComponent } from "@vue/composition-api";
import { displayApiResponseError } from "../utils/toast";
import { FetchBannersQueryContext } from "../api/dto/fetchBannersQueryContext";
import Banner from "../models/banner";
import BannerDialog from "../components/banners/BannerDialog.vue";
import BannersTable from "../components/banners/BannersTable.vue";
import { useBannerStore } from "../store/banner";

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}

export default defineComponent({
  name: "Banner",
  components: {
    BannerDialog,
    BannersTable,
  },
  setup() {
    const bannerStore = useBannerStore();
    return {
      bannerStore,
    };
  },
  data() {
    return {
      dialogMode: "create",
      isDeleteDialogOpen: false,
      isBannerDialogOpen: false,
      debounceTimer: setTimeout(() => "", 10000),
      bannerPendingDelete: null as Banner | null,
      query: "",
      selectedBanner: new Banner(),
      headers: [
        {
          active: true,
          text: "Ref. No.",
          value: "referenceNumber",
          width: "100px",
        },
        {
          active: true,
          text: "Name",
          value: "name",
          width: "18%",
        },
        {
          active: true,
          text: "Created",
          value: "createdAt",
        },
        {
          active: true,
          text: "Status",
          value: "status",
        },
        {
          active: true,
          text: "Start Date",
          value: "startsAt",
        },
        {
          active: true,
          text: "End Date",
          value: "endsAt",
        },
        {
          active: false,
          align: "right",
          sortable: false,
          text: "Actions",
          value: "actions",
        },
      ] as Record<string, unknown>[],
    };
  },
  computed: {
    options(): Options {
      return {
        sortDesc: this.bannerStore.getSortDesc,
        page: this.bannerStore.getPage,
        itemsPerPage: this.bannerStore.getLimit,
        sortBy: this.bannerStore.getSortBy,
      };
    },
  },
  watch: {
    query(): void {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchBanners();
      }, 500);
    },
  },
  methods: {
    fetchBanners(): void {
      let queryContext = new FetchBannersQueryContext(
        this.bannerStore.getLimit,
        this.bannerStore.getOffset,
        this.bannerStore.getSortBy,
        this.bannerStore.getSortDir,
        this.query
      );
      this.bannerStore.fetchAll(queryContext);
    },

    updateOptions(options: Options): void {
      this.bannerStore.setPagination(options).then(() => {
        this.fetchBanners();
      });
    },

    confirmDelete(banner: Banner): void {
      this.isDeleteDialogOpen = true;
      this.bannerPendingDelete = banner;
    },

    cancelConfirmDelete(): void {
      this.isDeleteDialogOpen = false;
    },

    deleteBanner(id: string): void {
      this.bannerStore
        .delete(id)
        .then(() => {
          this.isDeleteDialogOpen = false;
          this.fetchBanners();
        })
        .catch((error) => {
          displayApiResponseError(error);
        });
    },

    beginCreate(): void {
      this.selectedBanner = new Banner();
      this.dialogMode = "create";
      this.isBannerDialogOpen = true;
    },

    beginEditing(banner: Banner): void {
      this.selectedBanner = banner;
      this.dialogMode = "edit";
      this.isBannerDialogOpen = true;
    },

    beginViewing(banner: Banner): void {
      this.selectedBanner = banner;
      this.dialogMode = "view";
      this.isBannerDialogOpen = true;
    },

    finishEditing(): void {
      this.selectedBanner = new Banner();
      this.isBannerDialogOpen = false;
    },

    finishEditingAndReload(): void {
      this.selectedBanner = new Banner();
      this.isBannerDialogOpen = false;
      this.fetchBanners();
    },
  },
});
