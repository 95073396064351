











import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "App",
  components: {
    AppFooter,
    AppHeader,
  },
  data() {
    return {
      showRoutes: ["Banners", "Notifications", "Templates"] as string[],
    };
  },
  mounted() {
    this.$vuetify.theme.dark =
      localStorage.getItem("darkMode") === "true" || false;
  },
  computed: {
    theme(): string {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
});
