













































































import NullableField from "../NullableField.vue";
import { defineComponent } from "@vue/composition-api";
import { useTemplateStore } from "@/store/template";
import Template from "@/models/template";
import User from "@/models/user";

interface TableOptions {
  groupBy: never[];
  groupDesc: never[];
  multiSort: boolean;
  mustSort: boolean;
  sortDesc: never[];
  page: number;
  itemsPerPage: number;
  sortBy: never[];
}

export default defineComponent({
  name: "TemplatesTable",
  components: {
    NullableField,
  },
  setup() {
    const templateStore = useTemplateStore();
    return {
      templateStore,
    };
  },
  props: {
    headers: {
      default: () => [],
      type: Array,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Object,
    },
    totalTemplates: {
      default: 0,
      type: Number,
    },
    templates: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100],
      },
      tableOptions: {
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      } as TableOptions,
    };
  },
  watch: {
    options(val: TableOptions, _oldVal: TableOptions): void {
      Object.assign(this.tableOptions, val);
    },
    tableOptions(val: TableOptions, _oldVal: TableOptions): void {
      if (val === this.options) {
        return;
      }
      this.$emit("update:options", this.tableOptions);
    },
  },
  mounted() {
    Object.assign(this.tableOptions, this.options);
  },
  methods: {
    didClickRow(template: Template): void {
      if (!template.id) {
        return;
      }
      this.templateStore.fetchById(template.id);
      this.$router.push(`/templates/${template.id}`);
    },

    confirmApprove(template: Template): void {
      this.$emit("confirm-approve", template);
    },

    confirmDelete(template: Template): void {
      this.$emit("confirm-delete", template);
    },

    beginEditing(template: Template): void {
      this.$emit("edit", template);
    },

    iconSrc(iconName: string): string {
      if (this.$vuetify.theme.dark) {
        return require(`../../assets/icons/${iconName}-w.png`);
      } else {
        return require(`../../assets/icons/${iconName}.png`);
      }
    },

    initials(user: User): string {
      if (!user.name) {
        return "?";
      }
      const parts = user.name.split(" ");
      const first = parts[0] ? parts[0].charAt(0) : "";
      const last = parts[1] ? parts[1].charAt(0) : "";
      return `${first}${last}`;
    },

    tooltip(user: User): string {
      return `${user.name} (${user.email})`;
    },

    didSelectRow(template: Template): void {
      this.$emit("edit", template);
    },
  },
});
