var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('v-card-text',{staticStyle:{"margin-left":"0px","padding":"40px"}},[_c('v-row',[_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.templateStore.isLoading),expression:"templateStore.isLoading"}]},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1),_c('v-col',{staticClass:"background",staticStyle:{"padding-right":"12px"},attrs:{"cols":16}},[_c('p',{staticClass:"text-h4 text--primary font-weight-bold title",domProps:{"textContent":_vm._s(_vm.cardTitle)}}),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Name")]),_c('ValidationProvider',{ref:"name",attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('v-text-field',{attrs:{"flat":"","placeholder":"Remote Services Outage","required":"","disabled":_vm.isViewing,"error-messages":errors,"success":valid},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})}}],null,true)})],1),_c('v-col',[_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Language")]),_c('v-select',{attrs:{"items":_vm.languageOptions,"item-text":"label","label":"Standard"},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1),_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v(" Message Title ")]),_c('v-textarea',{attrs:{"flat":"","required":"","placeholder":"Remote Services Outage Notice","rows":1,"disabled":_vm.isViewing},model:{value:(_vm.messageTitle),callback:function ($$v) {_vm.messageTitle=$$v},expression:"messageTitle"}}),_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v("Message Body")]),_c('ValidationProvider',{ref:"messagebody",attrs:{"name":"Message Body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('v-textarea',{attrs:{"flat":"","required":"","placeholder":"Remote Services will be down for maintenance starting 9 PM CST. Here are some additional details.","rows":_vm.textAreaRows,"disabled":_vm.isViewing,"error-messages":errors,"success":valid},model:{value:(_vm.messageBody),callback:function ($$v) {_vm.messageBody=$$v},expression:"messageBody"}})}}],null,true)}),_c('p',{staticClass:"text-h6 font-weight-bold text--primary"},[_vm._v(" Internal Notes ")]),_c('v-textarea',{attrs:{"flat":"","placeholder":"Template for notifying customers of a Remote Services outage. This text will not be displayed to customers.","rows":2,"disabled":_vm.isViewing},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('v-btn',{staticClass:"text-none font-weight-bold elevation-0",staticStyle:{"margin-right":"30px"},attrs:{"type":"submit","width":"170px","height":"50px","color":"primary","large":""}},[_vm._v("Save ")]),_c('v-btn',{staticClass:"text-none font-weight-bold elevation-0",attrs:{"large":"","outlined":"","width":"170px","height":"50px","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel ")])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }