











































































































import { displayApiResponseError } from "../../utils/toast";
import Language from "../../models/language";
import { messages } from "vee-validate/dist/locale/en.json";
import { required } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import Template from "../../models/template";
import TemplateProperties from "../../models/templateProperties";
import User from "@/models/user";
import { defineComponent } from "@vue/composition-api";
import { useTemplateStore } from "@/store/template";
import { useUserStore } from "@/store/user";

extend("required", {
  ...required,
  message: messages["required"],
});

export default defineComponent({
  name: "TemplateDialog",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const templateStore = useTemplateStore();
    const useStore = useUserStore();
    return {
      templateStore,
      useStore,
    };
  },
  props: {
    mode: {
      default: "view",
      type: String,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      createdAt: null as string | null,
      createdBy: null as User | null,
      id: "" as string | null,
      messageBody: "" as string | null,
      messageTitle: "" as string | null,
      name: "" as string | null,
      notes: "" as string | null,
      updatedAt: "" as string | null,
      language: Language.ENGLISH as Language | null,
      textAreaRows: (2 as number) || null,
      valid: false,
      vins: [] as string[],
      languageOptions: [
        { label: "English", value: Language.ENGLISH },
        { label: "French", value: Language.FRENCH },
        { label: "Spanish", value: Language.SPANISH },
      ] as Record<string, string>[],
      unwatchFilters: (): void => {
        return;
      },
      unwatchQuery: (): void => {
        return;
      },
      unwatchSelectedTemplate: (): void => {
        return;
      },
      unwatchStatus: (): void => {
        return;
      },
    };
  },
  computed: {
    cardTitle(): string {
      switch (this.mode) {
        case "view":
          return `Template: ${this.value.name}`;
        case "edit":
          return `Editing Template: ${this.value.name}`;
        case "create":
          return `Create New Template`;
        default:
          return `Template`;
      }
    },

    isViewing(): boolean {
      return this.mode === "view";
    },
  },
  watch: {
    value(_val: Template, _oldVal: Template): void {
      this.setProperties();
    },
  },
  mounted(): void {
    this.setProperties();
  },
  methods: {
    save(): void {
      if (this.templateStore.isLoading) {
        return;
      }

      var properties: TemplateProperties = new TemplateProperties({});

      properties.language = this.language;
      properties.messageBody = this.messageBody;
      properties.messageTitle = this.messageTitle;
      properties.name = this.name;
      properties.notes = this.notes;

      if (this.value.id) {
        this.templateStore
          .update({
            id: this.value.id,
            properties: properties,
          })
          .then(() => {
            this.$emit("done");
          })
          .catch((error) => {
            displayApiResponseError(error);
          });
      } else {
        this.templateStore
          .create(properties)
          .then(() => {
            this.$emit("done");
          })
          .catch((error) => {
            displayApiResponseError(error);
          });
      }
    },

    setProperties(): void {
      this.createdAt = this.value.createdAt;
      this.id = this.value.id;
      this.createdBy = this.value.createdBy;
      this.messageBody = this.value.messageBody;
      this.messageTitle = this.value.messageTitle;
      this.name = this.value.name;
      this.notes = this.value.notes;
      this.updatedAt = this.value.updatedAt;
      this.language = this.value.language;

      (
        this.$refs.messagebody as InstanceType<typeof ValidationProvider>
      ).reset();
      (this.$refs.name as InstanceType<typeof ValidationProvider>).reset();
    },
  },
});
