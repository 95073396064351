import { defineStore } from "pinia";
import User from "../../models/user";
import {
  ACCESS_TOKEN,
  CODE_CHALLENGE,
  CODE_VERIFIER,
  EXPIRES_AT,
  ID_TOKEN,
  REFRESH_TOKEN,
  PROFILE,
  USER_INFO,
} from "../../utils/constant";
import { generatePkceCodes } from "@/utils/authUtils";
import {
  AuthResponse,
  getTokenFromAuthCode,
  getUserInfo,
  signIn,
} from "@/api/auth";

interface AuthState {
  accessToken: string | null;
  codeChallenge: string | null;
  codeVerifier: string | null;
  expiresAt: string | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  idToken: string | null;
  loginError: string | null;
  profile: User | null;
  refreshToken: string | null;
  userInfo: string | null;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => {
    return {
      accessToken: localStorage.getItem(ACCESS_TOKEN),
      codeChallenge: localStorage.getItem(CODE_CHALLENGE),
      codeVerifier: localStorage.getItem(CODE_VERIFIER),
      expiresAt: localStorage.getItem(EXPIRES_AT),
      isLoading: false,
      loginError: null,
      isLoggedIn: !!localStorage.getItem(ACCESS_TOKEN),
      idToken: localStorage.getItem(ID_TOKEN),
      profile: User.fromLocalStorageJSON(
        JSON.parse(localStorage.getItem(PROFILE) || "{}")
      ),
      userInfo: localStorage.getItem(USER_INFO),
      refreshToken: localStorage.getItem(REFRESH_TOKEN),
    };
  },
  getters: {
    getAccessToken(state: AuthState): string | null {
      return state.accessToken;
    },

    getCodeChallenge(state: AuthState): string | null {
      return state.codeChallenge;
    },

    getCodeVerifier(state: AuthState): string | null {
      return state.codeVerifier;
    },

    getExpiresAt(state: AuthState): string | null {
      return state.expiresAt;
    },

    getIdToken(state: AuthState): string | null {
      return state.idToken;
    },

    getIsLoggedIn(state: AuthState): boolean | null {
      return state.isLoggedIn;
    },

    getLoginError(state: AuthState): string | null {
      return state.loginError;
    },

    getProfile(state: AuthState): User | null {
      return state.profile;
    },

    getRefreshToken(state: AuthState): string | null {
      return state.refreshToken;
    },

    isAuthenticated(state: AuthState): boolean {
      return state.isLoggedIn;
    },
  },
  actions: {
    async fetchToken(
      state: AuthState,
      params: { authCode: string; verifier: string }
    ) {
      try {
        const authDetails = await getTokenFromAuthCode(
          params.authCode,
          params.verifier
        );

        const accessToken = authDetails.access_token;
        const refreshToken = authDetails.refresh_token;
        const idToken = authDetails.id_token;
        const expires_in = authDetails.expires_in;
        const refresh = refreshToken ? refreshToken.toString() : "";

        this.loginSuccess(
          state,
          accessToken,
          refresh.toString(),
          idToken,
          expires_in
        );
        const profileDetail = await getUserInfo(accessToken);
        this.fetchProfileSuccess(state, profileDetail);
      } catch (error) {
        state.loginError = "error";
      }
    },

    async initStore(state: AuthState) {
      if (!state.accessToken && !state.codeChallenge) {
        // Construct a new code challenge & verifier
        const pkce = generatePkceCodes();
        state.codeChallenge = pkce.codeChallenge;
        state.codeVerifier = pkce.codeVerifier;
        localStorage.setItem(CODE_VERIFIER, pkce.codeVerifier);
        localStorage.setItem(CODE_CHALLENGE, pkce.codeChallenge);
      }

      const notExpiredYet =
        Number(state.expiresAt) - Math.floor(Date.now() / 1000) > 0;

      if (!state.codeChallenge && notExpiredYet) {
        const pkce = generatePkceCodes();
        state.codeChallenge = pkce.codeChallenge;
        state.codeVerifier = pkce.codeVerifier;
        localStorage.setItem(CODE_VERIFIER, pkce.codeVerifier);
        localStorage.setItem(CODE_CHALLENGE, pkce.codeChallenge);
      }
    },

    async saveToken(state: AuthState, token: string) {
      localStorage.setItem(ACCESS_TOKEN, token);
      state.isLoading = false;
      state.accessToken = token;
      state.isLoggedIn = true;
    },

    async signIn(codeChallenge: string) {
      signIn(codeChallenge);
    },

    fetchProfileSuccess(state: AuthState, profile: AuthResponse) {
      localStorage.setItem(USER_INFO, JSON.stringify(profile));
      state.userInfo = JSON.stringify(profile);
    },

    loginSuccess(
      state: AuthState,
      access_token: string,
      refresh_token: string,
      id_token: string,
      expires_in: number
    ) {
      localStorage.setItem(ACCESS_TOKEN, access_token);
      state.isLoading = false;
      state.accessToken = access_token;

      if (refresh_token) {
        state.refreshToken = refresh_token;
        localStorage.setItem(REFRESH_TOKEN, refresh_token);
      }

      state.codeVerifier = null;
      state.codeChallenge = null;
      state.isLoggedIn = true;
      state.idToken = id_token;

      // Calculate when the token expires
      const currentTime = Math.floor(Date.now() / 1000);
      const expiresAt = currentTime + expires_in;
      state.expiresAt = expiresAt.toString();

      // Cache values in local storage
      localStorage.setItem(ACCESS_TOKEN, access_token);
      localStorage.setItem(ID_TOKEN, id_token);
      localStorage.setItem(EXPIRES_AT, expiresAt.toString());

      // Remove PKCE Codes from localStorage
      localStorage.removeItem(CODE_VERIFIER);
      localStorage.removeItem(CODE_CHALLENGE);
    },

    async logout() {
      this.accessToken = null;
      this.profile = null;
      this.refreshToken = null;
      this.idToken = null;
      this.expiresAt = null;
      this.isLoggedIn = false;

      localStorage.removeItem(PROFILE);
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(ID_TOKEN);
      localStorage.removeItem(EXPIRES_AT);
      localStorage.removeItem(USER_INFO);
    },
  },
});
