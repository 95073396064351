import Language from "./language";

export default class TemplateProperties {
  language: Language | null = null;
  messageBody: string | null = null;
  messageTitle: string | null = null;
  name: string | null = null;
  notes: string | null = null;

  constructor(json: Record<string, unknown>) {
    this.language = (json.language as Language) ?? null;
    this.messageBody = (json.messageBody as string) ?? null;
    this.messageTitle = (json.messageTitle as string) ?? null;
    this.name = (json.name as string) ?? null;
    this.notes = (json.notes as string) ?? null;
  }
}
