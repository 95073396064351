import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import ProfilePayload from "../models/profilePayload";
import Response from "../models/response";
import router from "../router";
import Util from "./util";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  getProfile(): Promise<Response<ProfilePayload>> {
    const url = Util.apiUrl("/v1/profile");

    return axios
      .get<Response<ProfilePayload>>(url, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<ProfilePayload>>) =>
        Util.handleResponse<ProfilePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<ProfilePayload>>) =>
        Util.handleError<ProfilePayload>(error)
      );
  },
};
