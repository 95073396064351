











































import { defineComponent } from "@vue/composition-api";
import ErrorBox from "../components/ErrorBox.vue";
import { useAuthStore } from "../store/auth";

export default defineComponent({
  name: "Login",
  components: {
    ErrorBox,
  },
  props: {
    token: {
      default: null,
      type: String,
    },
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  mounted() {
    if (process.env.VUE_APP_COGNITO) {
      return;
    }

    if (!this.token) {
      window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      return;
    }

    this.authStore.saveToken(this.authStore, this.token).then(() => {
      this.$router.push("/notifications");
    });
  },
  methods: {
    requestLogin(): void {
      this.authStore.initStore(this.authStore).then(() => {
        if (this.authStore.codeChallenge !== null) {
          this.authStore.signIn(this.authStore.codeChallenge);
        }
      });
    },
  },
});
