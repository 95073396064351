import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { FetchCustomersQueryContext } from "./dto/fetchCustomersQueryContext";
import CustomerPagePayload from "../models/customerPagePayload";
import qs from "querystring";
import Response from "../models/response";
import router from "../router";
import Util from "./util";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  all(
    params: FetchCustomersQueryContext
  ): Promise<Response<CustomerPagePayload>> {
    const url = Util.apiUrl("/v1/customers");

    return axios
      .get<Response<CustomerPagePayload>>(url, {
        headers: Util.authHeaders(),
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((resp: AxiosResponse<Response<CustomerPagePayload>>) =>
        Util.handleResponse<CustomerPagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<CustomerPagePayload>>) =>
        Util.handleError<CustomerPagePayload>(error)
      );
  },

  search(
    context: FetchCustomersQueryContext
  ): Promise<Response<CustomerPagePayload>> {
    const url = Util.apiUrl("/v1/customers/search");

    return axios
      .post<Response<CustomerPagePayload>>(url, context, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<CustomerPagePayload>>) =>
        Util.handleResponse<CustomerPagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<CustomerPagePayload>>) =>
        Util.handleError<CustomerPagePayload>(error)
      );
  },
};
