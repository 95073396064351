export class FetchFeaturesQueryContext {
  limit = 1000;
  offset = 0;
  sortBy: string[] = [];
  sortDir: string[] = [];

  constructor(
    limit: number,
    offset: number,
    sortBy: string[],
    sortDir: string[]
  ) {
    this.limit = limit;
    this.offset = offset;
    this.sortBy = sortBy;
    this.sortDir = sortDir;
  }
}
