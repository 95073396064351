import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { FetchTemplatesQueryContext } from "./dto/fetchTemplatesQueryContext";
import TemplatePagePayload from "../models/templatePagePayload";
import TemplatePayload from "../models/templatePayload";
import TemplateProperties from "../models/templateProperties";
import qs from "querystring";
import Response from "../models/response";
import router from "../router";
import Util from "./util";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  all(
    params: FetchTemplatesQueryContext
  ): Promise<Response<TemplatePagePayload>> {
    const url = Util.apiUrl("/v1/templates");

    return axios
      .get<Response<TemplatePagePayload>>(url, {
        headers: Util.authHeaders(),
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((resp: AxiosResponse<Response<TemplatePagePayload>>) =>
        Util.handleResponse<TemplatePagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<TemplatePagePayload>>) =>
        Util.handleError<TemplatePagePayload>(error)
      );
  },

  create(properties: TemplateProperties): Promise<Response<TemplatePayload>> {
    const url = Util.apiUrl(`/v1/templates`);

    return axios
      .post<Response<TemplatePayload>>(url, properties, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<TemplatePayload>>) =>
        Util.handleResponse<TemplatePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<TemplatePayload>>) =>
        Util.handleError<TemplatePayload>(error)
      );
  },

  delete(id: string): Promise<Response<TemplatePayload>> {
    const url = Util.apiUrl(`/v1/templates/${id}`);

    return axios
      .delete<Response<TemplatePayload>>(url, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<TemplatePayload>>) =>
        Util.handleResponse<TemplatePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<TemplatePayload>>) =>
        Util.handleError<TemplatePayload>(error)
      );
  },

  getById(id: string): Promise<Response<TemplatePayload>> {
    const url = Util.apiUrl(`/v1/templates/${id}`);

    return axios
      .get<Response<TemplatePayload>>(url, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<TemplatePayload>>) =>
        Util.handleResponse<TemplatePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<TemplatePayload>>) =>
        Util.handleError<TemplatePayload>(error)
      );
  },

  update(
    id: string,
    properties: TemplateProperties
  ): Promise<Response<TemplatePayload>> {
    const url = Util.apiUrl(`/v1/templates/${id}`);

    return axios
      .put<Response<TemplatePayload>>(url, properties, {
        headers: Util.authHeaders(),
      })
      .then((resp: AxiosResponse<Response<TemplatePayload>>) =>
        Util.handleResponse<TemplatePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<TemplatePayload>>) =>
        Util.handleError<TemplatePayload>(error)
      );
  },
};
