import TemplatePagePayload from "../../models/templatePagePayload";
import TemplatePayload from "../../models/templatePayload";
import TemplateProperties from "../../models/templateProperties";
import Response from "../../models/response";
import { FetchTemplatesQueryContext } from "../../api/dto/fetchTemplatesQueryContext";
import Template from "../../models/template";
import TemplateApi from "@/api/template";
import { defineStore } from "pinia";

export interface TemplateState {
  detailedTemplate: Template | null;
  isLoading: boolean;
  limit: number;
  templateIds: (string | null)[];
  templates: Map<string | null, Template>;
  offset: number;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
  total: number | null;
}

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}
interface UpdateTemplateParameters {
  id: string;
  properties: TemplateProperties;
}

export const useTemplateStore = defineStore({
  id: "template",
  state: (): TemplateState => {
    return {
      detailedTemplate: null,
      isLoading: false,
      limit: 10,
      templateIds: [],
      templates: new Map<string, Template>(),
      page: 1,
      offset: 0,
      sortBy: ["createdAt"],
      sortDesc: [true],
      total: 0,
    };
  },
  getters: {
    getDetailedTemplate(state: TemplateState): Template | null {
      return state.detailedTemplate;
    },

    getTemplates(state: TemplateState): Template[] {
      return state.templateIds.map((templateId: string | null) => {
        return state.templates.get(templateId) as Template;
      });
    },

    getTemplateById:
      (state: TemplateState) =>
      (id: string): Template | undefined => {
        return state.templates.get(id);
      },

    getLimit(state: TemplateState): number {
      return state.limit;
    },

    getOffset(state: TemplateState): number {
      return state.offset;
    },

    getPage(state: TemplateState): number {
      return state.page;
    },

    getSortBy(state: TemplateState): string[] {
      return state.sortBy;
    },

    getSortDesc(state: TemplateState): boolean[] {
      return state.sortDesc;
    },

    getSortDir(state: TemplateState): string[] {
      return state.sortDesc.map((x: boolean) => {
        return x ? "desc" : "asc";
      });
    },
  },
  actions: {
    create(properties: TemplateProperties): Promise<Response<TemplatePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        TemplateApi.create(properties)
          .then((resp: Response<TemplatePayload>) => {
            if (!resp.payload || !resp.payload.template) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.templates.set(resp.payload.template.id, resp.payload.template);
            this.templateIds.push(resp.payload.template.id);
            resolve(resp);
          })
          .catch((resp: Response<TemplatePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    delete(id: string): Promise<Response<TemplatePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        TemplateApi.delete(id)
          .then((resp: Response<TemplatePayload>) => {
            if (!resp.payload || !resp.payload.template) {
              this.isLoading = false;

              reject(resp);
              return;
            }

            this.isLoading = false;
            this.templates.set(resp.payload.template.id, resp.payload.template);
            this.templateIds.push(resp.payload.template.id);
            resolve(resp);
          })
          .catch((resp: Response<TemplatePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    fetchAll(
      queryContext: FetchTemplatesQueryContext
    ): Promise<Response<TemplatePagePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        TemplateApi.all(queryContext)
          .then((resp: Response<TemplatePagePayload>) => {
            if (
              !resp.payload ||
              !resp.payload.templates ||
              !resp.payload.pagination
            ) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.total = resp.payload.pagination.total;
            this.templateIds = resp.payload.templates.map(
              (n: Template) => n.id
            );
            const updatedTemplate = resp.payload.templates.reduce(
              (acc: Map<string, Template>, n: Template) => {
                if (!n.id) {
                  return acc;
                }
                acc.set(n.id, n);
                return acc;
              },
              new Map<string, Template>()
            );

            this.templates = updatedTemplate;
            resolve(resp);
          })
          .catch((resp: Response<TemplatePagePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    fetchById(id: string): Promise<Response<TemplatePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        this.detailedTemplate = null;
        TemplateApi.getById(id)
          .then((resp: Response<TemplatePayload>) => {
            if (!resp.payload || !resp.payload.template) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.detailedTemplate = resp.payload.template;
            resolve(resp);
          })
          .catch((resp: Response<TemplatePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    setPagination(options: Options): Promise<void> {
      return new Promise((resolve, _reject) => {
        this.limit = options.itemsPerPage;
        this.offset = (options.page - 1) * options.itemsPerPage;
        this.page = options.page;
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
        resolve();
      });
    },

    update(
      params: UpdateTemplateParameters
    ): Promise<Response<TemplatePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        TemplateApi.update(params.id, params.properties)
          .then((resp: Response<TemplatePayload>) => {
            if (!resp.payload || !resp.payload.template) {
              this.isLoading = false;

              reject(resp);
              return;
            }

            this.isLoading = false;
            this.templates.set(resp.payload.template.id, resp.payload.template);
            resolve(resp);
          })
          .catch((resp: Response<TemplatePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
  },
});
