






































































































































































import { useNotificationStore } from "@/store/notification";
import { defineComponent } from "@vue/composition-api";
import { displayApiResponseError } from "@/utils/toast";
import { FetchNotificationsQueryContext } from "../api/dto/fetchNotificationsQueryContext";
import Notification from "../models/notification";
import NotificationDialog from "../components/notifications/NotificationDialog.vue";
import NotificationsTable from "../components/notifications/NotificationsTable.vue";

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}

export default defineComponent({
  name: "Notification",
  components: {
    NotificationDialog,
    NotificationsTable,
  },
  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  data() {
    return {
      dialogMode: "create",
      isApproveDialogOpen: false,
      isDeleteDialogOpen: false,
      isNotificationDialogOpen: false,
      notificationPendingApproval: null as Notification | null,
      notificationPendingDelete: null as Notification | null,
      debounceTimer: setTimeout(() => "", 10000),
      query: "",
      selectedNotification: new Notification(),
      headers: [
        {
          active: true,
          text: "Ref. No.",
          value: "referenceNumber",
        },
        {
          active: true,
          text: "Status",
          value: "status",
        },
        {
          active: true,
          text: "Name",
          value: "name",
          width: "30%",
        },
        {
          active: true,
          text: "Created",
          value: "createdAt",
        },
        {
          active: true,
          text: "Approved",
          value: "approvedAt",
        },
        {
          active: false,
          align: "right",
          sortable: false,
          text: "Actions",
          value: "actions",
        },
      ] as Record<string, unknown>[],
    };
  },
  computed: {
    options(): Options {
      return {
        sortDesc: this.notificationStore.getSortDesc,
        page: this.notificationStore.getPage,
        itemsPerPage: this.notificationStore.getLimit,
        sortBy: this.notificationStore.getSortBy,
      };
    },
  },
  watch: {
    query(): void {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchNotifications();
      }, 500);
    },
  },
  methods: {
    fetchNotifications(): void {
      let queryContext = new FetchNotificationsQueryContext(
        this.notificationStore.getLimit,
        this.notificationStore.getOffset,
        this.notificationStore.getSortBy,
        this.notificationStore.getSortDir,
        this.query
      );

      this.notificationStore.fetchAll(queryContext);
    },

    updateOptions(options: Options): void {
      this.notificationStore.setPagination(options).then(() => {
        this.fetchNotifications();
      });
    },

    confirmApprove(notification: Notification): void {
      this.isApproveDialogOpen = true;
      this.notificationPendingApproval = notification;
    },

    confirmDelete(notification: Notification): void {
      this.isDeleteDialogOpen = true;
      this.notificationPendingDelete = notification;
    },

    cancelConfirmApprove(): void {
      this.isApproveDialogOpen = false;
    },

    cancelConfirmDelete(): void {
      this.isDeleteDialogOpen = false;
    },

    approve(id: string): void {
      this.notificationStore.approve(id).then(() => {
        this.isApproveDialogOpen = false;
        setTimeout(() => this.fetchNotifications(), 1000);
      });
    },

    deleteNotif(id: string): void {
      this.notificationStore
        .delete(id)
        .then(() => {
          this.isDeleteDialogOpen = false;
          this.fetchNotifications();
        })
        .catch((error) => {
          displayApiResponseError(error);
        });
    },

    beginCreate(): void {
      this.selectedNotification = new Notification();
      this.dialogMode = "create";
      this.isNotificationDialogOpen = true;
    },

    beginEditing(notification: Notification): void {
      this.selectedNotification = notification;
      this.dialogMode = "edit";
      this.isNotificationDialogOpen = true;
    },

    beginViewing(notification: Notification): void {
      this.selectedNotification = notification;
      this.dialogMode = "view";
      this.isNotificationDialogOpen = true;
    },

    finishEditing(): void {
      this.selectedNotification = new Notification();
      this.isNotificationDialogOpen = false;
    },

    finishEditingAndReload(): void {
      this.selectedNotification = new Notification();
      this.isNotificationDialogOpen = false;
      this.fetchNotifications();
    },
  },
});
