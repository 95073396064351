import User from "./user";
import Language from "./language";

export default class Template {
  createdAt: string | null;
  createdBy: User | null;
  id: string | null;
  language: Language | null;
  messageBody: string | null;
  messageTitle: string | null;
  name: string | null;
  notes: string | null;
  referenceNumber: string | null;
  updatedAt: string | null;

  constructor() {
    this.createdAt = null;
    this.createdBy = null;
    this.id = null;
    this.language = Language.ENGLISH;
    this.messageBody = null;
    this.messageTitle = null;
    this.name = null;
    this.notes = null;
    this.referenceNumber = null;
    this.updatedAt = null;
  }

  static fromJSON(json: Record<string, unknown>): Template {
    const template = new Template();

    template.createdAt = (json.createdAt as string) ?? null;
    template.createdBy = User.fromJSON(
      json.createdBy as Record<string, unknown>
    );
    template.id = json.id as string;
    template.language = (json.language as Language) ?? null;
    template.messageBody = (json.messageBody as string) ?? null;
    template.messageTitle = (json.messageTitle as string) ?? null;
    template.name = (json.name as string) ?? null;
    template.notes = (json.notes as string) ?? null;
    template.referenceNumber = (json.referenceNumber as string) ?? null;
    template.updatedAt = (json.updatedAt as string) ?? null;

    return template;
  }

  static templateNameWithRefNumber(template: Template): string {
    return template.referenceNumber
      ? `${template.referenceNumber} - ${template.name}`
      : `${template.name}`;
  }
}
