import crypto from "crypto";
import base64url from "base64url";

interface PkceCodes {
  codeVerifier: string;
  codeChallenge: string;
}

export const generatePkceCodes = (): PkceCodes => {
  const verifier = crypto.randomBytes(43).toString("hex");
  const challenge = base64url.fromBase64(
    crypto.createHash("sha256").update(verifier).digest("base64")
  );

  return {
    codeVerifier: verifier,
    codeChallenge: challenge,
  };
};
