import DeviceType from "./deviceType";
import Brand from "./brand";
import Language from "./language";
import Region from "./region";

export default class NotificationProperties {
  appVersions: string[] = [];
  brands: Brand[] = [];
  customerGuids: string[] = [];
  deviceTypes: DeviceType[] = [];
  deviceVersions: string[] = [];
  dryRun = false;
  generations: string[] = [];
  language: Language | null = null;
  messageBody: string | null = null;
  messageTitle: string | null = null;
  modelCodes: string[] = [];
  modelYears: string[] = [];
  name: string | null = null;
  notes: string | null = null;
  regions: Region[] = [];
  subscriptions: string[] = [];
  vins: string[] = [];

  constructor(json: Record<string, unknown>) {
    this.appVersions = (json.appVersions as string[]) ?? [];
    this.brands = (json.brands as Brand[]) ?? [];
    this.customerGuids = (json.customerGuids as string[]) ?? [];
    this.deviceTypes = (json.deviceTypes as DeviceType[]) ?? [];
    this.deviceVersions = (json.deviceVersions as string[]) ?? [];
    this.dryRun = (json.dryRun as boolean) ?? false;
    this.generations = (json.generations as string[]) ?? [];
    this.language = (json.language as Language) ?? null;
    this.messageBody = (json.messageBody as string) ?? null;
    this.messageTitle = (json.messageTitle as string) ?? null;
    this.modelCodes = (json.modelCodes as string[]) ?? [];
    this.modelYears = (json.modelYears as string[]) ?? [];
    this.name = (json.name as string) ?? null;
    this.notes = (json.notes as string) ?? null;
    this.regions = (json.regions as Region[]) ?? [];
    this.subscriptions = (json.subscriptions as string[]) ?? [];
    this.vins = (json.vins as string[]) ?? [];
  }
}
