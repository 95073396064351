import Vue from "vue";
import Response from "../models/response";

export const displayError = (title: string, description: string): void => {
  Vue.notify({
    title: title,
    text: description,
    type: "error",
  });
};

export const displayApiResponseError = (
  error: Response<Record<string, unknown>>
): void => {
  Vue.notify({
    title: `${error.status?.messages[0].responseCode}`,
    text: error.status?.messages[0].detailedDescription
      ? error.status?.messages[0].detailedDescription
      : "An error occurred.",
    type: "error",
  });
};
