




























































































































































































































































import Banner from "../../models/banner";
import BannerProperties from "../../models/bannerProperties";
import BannerStatus from "../../models/bannerStatus";
import CustomerFiltersPanel from "../CustomerFiltersPanel.vue";
import { DateTime } from "luxon";
import { defineComponent } from "@vue/composition-api";
import { displayError, displayApiResponseError } from "../../utils/toast";
import Feature from "../../models/feature";
import FeatureApi from "@/api/feature";
import FeaturePagePayload from "../../models/featurePagePayload";
import { FetchCustomersQueryContext } from "../../api/dto/fetchCustomersQueryContext";
import { FetchFeaturesQueryContext } from "../../api/dto/fetchFeaturesQueryContext";
import { FetchTemplatesQueryContext } from "../../api/dto/fetchTemplatesQueryContext";
import { Filters } from "../../models/customerFilters";
import Language from "../../models/language";
import { messages } from "vee-validate/dist/locale/en.json";
import { required } from "vee-validate/dist/rules";
import Response from "../../models/response";
import Template from "../../models/template";
import TemplateApi from "@/api/template";
import TemplatePagePayload from "../../models/templatePagePayload";
import { useBannerStore } from "@/store/banner";
import { useCustomerStore } from "@/store/customer";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { useUserStore } from "@/store/user";

extend("required", {
  ...required,
  message: messages["required"],
});

export default defineComponent({
  name: "BannerDialog",
  components: {
    CustomerFiltersPanel,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const bannerStore = useBannerStore();
    const customerStore = useCustomerStore();
    const userStore = useUserStore();
    return {
      bannerStore,
      customerStore,
      userStore,
    };
  },
  props: {
    mode: {
      default: "view",
      type: String,
    },
    value: {
      required: true,
      type: Object,
    },
    visible: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      debounceTimer: setTimeout(() => "", 10000),
      endsAt: null as string | null,
      errors: [],
      feature: null as string | null,
      features: [] as Feature[],
      filters: {
        appVersions: [],
        brands: [],
        customerGuids: [],
        deviceTypes: [],
        deviceVersions: [],
        generations: [],
        language: Language.ENGLISH,
        modelCodes: [],
        modelYears: [],
        regions: [],
        subscriptions: [],
        vins: [],
      } as Filters,
      menuStart: false,
      menuEnd: false,
      messageBody: null as string | null,
      messageTitle: null as string | null,
      name: null as string | null,
      notes: null as string | null,
      query: "",
      startsAt: null as string | null,
      status: BannerStatus.UNPUBLISHED,
      selectedTemplate: null as Template | null,
      showSchedulePickers: false,
      templates: [] as Template[],
      textAreaRows: 5,
      valid: false,
      unwatchFilters: (): void => {
        return;
      },
      unwatchQuery: (): void => {
        return;
      },
      unwatchSelectedTemplate: (): void => {
        return;
      },
      unwatchStatus: (): void => {
        return;
      },
    };
  },
  computed: {
    cardTitle(): string {
      switch (this.mode) {
        case "view":
          return `Banner ${this.value.referenceNumber}`;
        case "edit":
          return `Editing Banner ${this.value.referenceNumber}`;
        case "create":
          return `Create New Banner`;
        default:
          return `Banner`;
      }
    },
    hasCustomers(): boolean {
      return this.customerStore.total > 0;
    },
    isViewing(): boolean {
      return this.mode === "view";
    },
    // Computed property to workaround Deep Watchers not supporting newVal/oldVal comparisons on nested mutations.
    computedFilters(): Filters {
      return Object.assign({}, this.filters);
    },
  },
  watch: {
    value(_val: Banner, _oldVal: Banner): void {
      this.setProperties();
    },
    visible(_val: boolean, _oldVal: boolean): void {
      this.setProperties();

      if (_val) {
        this.loadWatchers();
        this.fetchCustomers();
        this.fetchFeatures();

        if (this.userStore.isAdmin && this.status === BannerStatus.SCHEDULED) {
          this.showSchedulePickers = true;
        }
      } else {
        this.unloadWatchers();
        this.selectedTemplate = null;
        this.showSchedulePickers = false;
      }
    },
  },
  mounted(): void {
    this.setProperties();
    this.loadWatchers();
    this.fetchCustomers();
    this.fetchFeatures();

    if (this.userStore.isAdmin && this.status === BannerStatus.SCHEDULED) {
      this.showSchedulePickers = true;
    }
  },
  methods: {
    fetchCustomers(): void {
      const queryContext = new FetchCustomersQueryContext();
      queryContext.appVersion = this.filters.appVersions;
      queryContext.brand = this.filters.brands;
      queryContext.customerGuid = this.filters.customerGuids;
      queryContext.deviceType = this.filters.deviceTypes;
      queryContext.deviceVersion = this.filters.deviceVersions;
      queryContext.generation = this.filters.generations;
      queryContext.language = this.filters.language;
      queryContext.modelCode = this.filters.modelCodes;
      queryContext.modelYear = this.filters.modelYears;
      queryContext.region = this.filters.regions;
      queryContext.subscription = this.filters.subscriptions;
      queryContext.vin = this.filters.vins;

      this.customerStore.search(queryContext);
    },
    fetchFeatures(): void {
      let queryContext = new FetchFeaturesQueryContext(1000, 0, [], []);

      FeatureApi.all(queryContext).then(
        (resp: Response<FeaturePagePayload>) => {
          if (!resp.payload || !resp.payload.features) {
            return;
          }

          this.features = resp.payload.features;
        }
      );
    },
    fetchTemplates(): void {
      let queryContext = new FetchTemplatesQueryContext(
        5,
        0,
        [],
        [],
        this.query,
        this.filters.language
      );

      TemplateApi.all(queryContext).then(
        (resp: Response<TemplatePagePayload>) => {
          if (!resp.payload || !resp.payload.templates) {
            return;
          }

          this.templates = resp.payload.templates;
        }
      );
    },
    formatDate(date: string | null): string | null {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatTemplateName(template: Template): string {
      return Template.templateNameWithRefNumber(template);
    },
    loadWatchers(): void {
      this.unwatchFilters = this.$watch(
        "computedFilters",
        (val: Filters, oldVal: Filters) => {
          if (val.language !== oldVal.language) {
            this.messageBody = null;
            this.messageTitle = null;
            this.selectedTemplate = null;
            this.templates = [];
            this.query = "";
          }
          this.fetchCustomers();
        },
        { deep: true }
      );
      this.unwatchQuery = this.$watch("query", () => {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
          this.fetchTemplates();
        }, 500);
      });
      this.unwatchSelectedTemplate = this.$watch(
        "selectedTemplate",
        (_val: Template, _oldVal: Template) => {
          this.messageBody = _val ? _val.messageBody : null;
          this.messageTitle = _val ? _val.messageTitle : null;
        }
      );
      this.unwatchStatus = this.$watch(
        "status",
        (_val: string, _oldVal: string) => {
          this.showSchedulePickers = _val === BannerStatus.SCHEDULED;
        }
      );
    },
    save(): void {
      if (this.bannerStore.isLoading) {
        return;
      }

      if (this.status === "SCHEDULED" && !this.endsAt && !this.startsAt) {
        displayError(
          "Scheduled Date Required",
          "Scheduled banners must include either a start date or an end date."
        );

        return;
      }

      var properties: BannerProperties = new BannerProperties({});

      properties.appVersions = this.filters.appVersions;
      properties.brands = this.filters.brands;
      properties.customerGuids = this.filters.customerGuids;
      properties.deviceTypes = this.filters.deviceTypes;
      properties.deviceVersions = this.filters.deviceVersions;
      properties.endsAt = this.endsAt;
      properties.feature = this.feature;
      properties.generations = this.filters.generations;
      properties.language = this.filters.language;
      properties.messageBody = this.messageBody;
      properties.messageTitle = this.messageTitle;
      properties.modelCodes = this.filters.modelCodes;
      properties.modelYears = this.filters.modelYears;
      properties.name = this.name;
      properties.notes = this.notes;
      properties.regions = this.filters.regions;
      properties.startsAt = this.startsAt;
      properties.status = this.status;
      properties.subscriptions = this.filters.subscriptions;
      properties.vins = this.filters.vins;

      if (this.value.id) {
        this.bannerStore
          .update({
            id: this.value.id,
            properties: properties,
          })
          .then(() => {
            this.$emit("done");
          })
          .catch((error) => {
            displayApiResponseError(error);
          });
      } else {
        this.bannerStore
          .create(properties)
          .then(() => {
            this.$emit("done");
          })
          .catch((error) => {
            displayApiResponseError(error);
          });
      }
    },
    setProperties(): void {
      this.filters.appVersions = this.value.appVersions;
      this.filters.brands = this.value.brands;
      this.filters.customerGuids = this.value.customerGuids;
      this.filters.deviceTypes = this.value.deviceTypes;
      this.filters.deviceVersions = this.value.deviceVersions;
      this.endsAt = this.value.endsAt
        ? DateTime.fromISO(this.value.endsAt).toISO()
        : null;
      this.errors = [];
      this.feature = this.value.feature;
      this.filters.generations = this.value.generations;
      this.filters.language = this.value.language;
      this.messageBody = this.value.messageBody;
      this.messageTitle = this.value.messageTitle;
      this.filters.modelCodes = this.value.modelCodes;
      this.filters.modelYears = this.value.modelYears;
      this.name = this.value.name;
      this.notes = this.value.notes;
      this.filters.regions = this.value.regions;
      this.startsAt = this.value.startsAt
        ? DateTime.fromISO(this.value.startsAt).toISO()
        : null;
      this.status = this.value.status;
      this.filters.subscriptions = this.value.subscriptions;
      this.filters.vins = this.value.vins;

      (this.$refs.feature as InstanceType<typeof ValidationProvider>).reset();
      (
        this.$refs.messagebody as InstanceType<typeof ValidationProvider>
      ).reset();
      (this.$refs.name as InstanceType<typeof ValidationProvider>).reset();
    },
    unloadWatchers(): void {
      this.unwatchFilters();
      this.unwatchQuery();
      this.unwatchSelectedTemplate();
      this.unwatchStatus();
    },
  },
});
