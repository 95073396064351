








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ErrorBox",
  props: {
    message: {
      default: "An error occurred.",
      type: String,
    },
  },
});
