











































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import { SubscriptionType } from "../models/notification";
import Language from "../models/language";
import Brand from "../models/brand";
import Region from "../models/region";
import DeviceType from "../models/deviceType";
import TextFileUploader from "./TextFileUploader.vue";
import { FetchFiltersQueryContext } from "@/api/dto/fetchFiltersQueryContext";
import FilterApi from "@/api/filter";
import FilterPagePayload from "@/models/filterPagePayload";
import Filter from "@/models/filter";
import Response from "@/models/response";

interface searchQueries {
  appVersion: string;
  deviceVersion: string;
  guid: string;
  modelCode: string;
  modelYear: string;
  vin: string;
}

interface fetchedFilters {
  appVersion: string[];
  deviceVersion: string[];
  guid: string[];
  modelCode: string[];
  modelYear: string[];
  vin: string[];
}

export default defineComponent({
  name: "CustomerFiltersPanel",
  components: {
    TextFileUploader,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    mode: {
      default: "view",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      brandOptions: [
        { label: "Toyota", value: Brand.TOYOTA },
        { label: "Lexus", value: Brand.LEXUS },
        { label: "Subaru", value: Brand.SUBARU },
      ] as Record<string, string>[],

      debounceTimer: setTimeout(() => "", 10000),

      deviceOptions: [
        { label: "Android", value: DeviceType.ANDROID },
        { label: "iOS", value: DeviceType.IOS },
      ] as Record<string, string>[],

      fetchedFilters: {
        appVersion: [] as string[],
        deviceVersion: [] as string[],
        guid: [] as string[],
        modelCode: [] as string[],
        modelYear: [] as string[],
        vin: [] as string[],
      },

      generationOptions: [
        { label: "GR86", value: "GR86" },
        { label: "PRE17CY", value: "PRE17CY" },
        { label: "17CY", value: "17CY" },
        { label: "17CYPLUS", value: "17CYPLUS" },
        { label: "21MM", value: "21MM" },
      ] as Record<string, string>[],

      languageOptions: [
        { label: "English", value: Language.ENGLISH },
        { label: "French", value: Language.FRENCH },
        { label: "Spanish", value: Language.SPANISH },
      ] as Record<string, string>[],

      panels: [] as number[] | [],

      regionOptions: [
        { label: "US", value: Region.US },
        { label: "Canada", value: Region.CANADA },
        { label: "Mexico", value: Region.MEXICO },
        { label: "Hawaii", value: Region.HAWAII },
        { label: "Puerto Rico", value: Region.PUERTO_RICO },
      ] as Record<string, string>[],

      searchQueries: {
        appVersion: "",
        deviceVersion: "",
        guid: "",
        modelCode: "",
        modelYear: "",
        vin: "",
      },

      subscriptionOptions: [
        { label: "Remote Service", value: SubscriptionType.REMOTE },
        { label: "EV Remote Service", value: SubscriptionType.EV_REMOTE },
        { label: "20TM Remote Service", value: SubscriptionType.TM_REMOTE },
        {
          label: "20TM EV Remote Service",
          value: SubscriptionType.EV_TM_REMOTE,
        },
        { label: "Next Gen Remote Service", value: SubscriptionType.REMOTE_V2 },
        {
          label: "Remote Lite Mechanical",
          value: SubscriptionType.REMOTE_LITE_MECHANICAL,
        },
        {
          label: "Remote Lite Smart",
          value: SubscriptionType.REMOTE_LITE_SMART,
        },
        {
          label: "Remote Plus Digital Key",
          value: SubscriptionType.REMOTE_DIGITAL_KEY,
        },
        {
          label: "Remote V2 Plus Digital Key",
          value: SubscriptionType.REMOTE_V2_DIGITAL_KEY,
        },
        {
          label: "EV Remote Plus Digital Key",
          value: SubscriptionType.EV_REMOTE_DIGITAL_KEY,
        },
        {
          label: "Dynamic Navigation",
          value: SubscriptionType.DYNAMIC_NAVIGATION,
        },
        {
          label: "Destination Assist",
          value: SubscriptionType.DESTNATION_ASSIST,
        },
        { label: "Service Connect", value: SubscriptionType.SERVICE_CONNECT },
      ] as Record<string, string>[],
    };
  },
  watch: {
    visible(_val: boolean, _oldVal: boolean): void {
      if (!_val) {
        this.panels = [];
        this.guidUpload = null;
      }
    },
    "searchQueries.appVersion": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("appVersion");
      }, 500);
    },
    "searchQueries.deviceVersion": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("deviceVersion");
      }, 500);
    },
    "searchQueries.guid": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("guid");
      }, 500);
    },
    "searchQueries.modelCode": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("modelCode");
      }, 500);
    },
    "searchQueries.modelYear": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("modelYear");
      }, 500);
    },
    "searchQueries.vin": function () {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.fetchFilters("vin");
      }, 500);
    },
  },
  computed: {
    isViewing(): boolean {
      return this.mode === "view";
    },
  },
  methods: {
    fetchFilters(filterName: string): void {
      let queryContext = new FetchFiltersQueryContext(
        20,
        0,
        [],
        [],
        this.searchQueries[filterName as keyof searchQueries]
      );

      FilterApi.all(filterName, queryContext).then(
        (resp: Response<FilterPagePayload>) => {
          if (!resp.payload || !resp.payload.filters) {
            return;
          }

          this.fetchedFilters[filterName as keyof fetchedFilters] =
            resp.payload.filters.map((f) => f.value);
        }
      );
    },
    formatFilterValue(filter: Filter): string {
      return filter.value;
    },
  },
});
