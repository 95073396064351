export class FetchFiltersQueryContext {
  limit = 20;
  offset = 0;
  q?: string;
  sortBy: string[] = [];
  sortDir: string[] = [];

  constructor(
    limit: number,
    offset: number,
    sortBy: string[],
    sortDir: string[],
    q?: string
  ) {
    this.limit = limit;
    this.offset = offset;
    if (q && q !== "") this.q = q;
    this.sortBy = sortBy;
    this.sortDir = sortDir;
  }
}
