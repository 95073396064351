












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TextFileUploader",
  data() {
    return {
      uploadedTextFile: null as File | null,
    };
  },
  methods: {
    readUploadedTextFile(e: File): void {
      this.uploadedTextFile = e;

      if (!this.uploadedTextFile) {
        this.$emit("input", []);
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const filtersArray = event.target?.result
          ?.toString()
          .split(/\r?\n/)
          .filter((element) => element);

        this.$emit("input", [...(new Set(filtersArray) as Set<string>)]);
      };

      reader.readAsText(e);
    },
  },
});
