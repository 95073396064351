





import { defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  name: "NullableField",
  props: {
    type: {
      default: "string",
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
  },
  computed: {
    displayValue(): string {
      if (this.value === null) {
        return "";
      }
      if (this.type === "datetime") {
        return this.datetimeString(this.value);
      } else {
        return this.value;
      }
    },
  },
  methods: {
    datetimeString(datetime: string): string {
      return DateTime.fromISO(datetime).toFormat("LLL dd, yy, t ZZZZ");
    },
  },
});
