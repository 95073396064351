import axios from "axios";
import { AxiosError, AxiosResponse } from "axios";
import { FetchFeaturesQueryContext } from "./dto/fetchFeaturesQueryContext";
import qs from "querystring";
import Response from "../models/response";
import router from "../router";
import Util from "./util";
import FeaturePagePayload from "@/models/featurePagePayload";

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (Util.isUnauthorized(error)) {
      localStorage.removeItem("accessToken");
      if (process.env.VUE_APP_COGNITO) {
        router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default {
  all(
    params: FetchFeaturesQueryContext
  ): Promise<Response<FeaturePagePayload>> {
    const url = Util.apiUrl("/v1/features");

    return axios
      .get<Response<FeaturePagePayload>>(url, {
        headers: Util.authHeaders(),
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((resp: AxiosResponse<Response<FeaturePagePayload>>) =>
        Util.handleResponse<FeaturePagePayload>(resp.data)
      )
      .catch((error: AxiosError<Response<FeaturePagePayload>>) =>
        Util.handleError<FeaturePagePayload>(error)
      );
  },
};
