







import { defineComponent } from "@vue/composition-api";
import { version } from "../../package.json";

export default defineComponent({
  name: "AppFooter",
  computed: {
    version(): string {
      return version;
    },
  },
});
