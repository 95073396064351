import Banner from "../../models/banner";
import BannerApi from "@/api/banner";
import BannerPagePayload from "../../models/bannerPagePayload";
import BannerPayload from "../../models/bannerPayload";
import BannerProperties from "../../models/bannerProperties";
import { defineStore } from "pinia";
import { FetchBannersQueryContext } from "../../api/dto/fetchBannersQueryContext";
import Response from "../../models/response";

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}

interface UpdateBannerParameters {
  id: string;
  properties: BannerProperties;
}

interface BannerState {
  detailedBanner: Banner | null;
  isLoading: boolean;
  limit: number;
  bannerIds: (string | null)[];
  banners: Map<string | null, Banner>;
  offset: number;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
  total: number | null;
}

export const useBannerStore = defineStore({
  id: "banner",
  state: (): BannerState => {
    return {
      detailedBanner: null,
      isLoading: false,
      limit: 10,
      bannerIds: [],
      banners: new Map<string, Banner>(),
      page: 1,
      offset: 0,
      sortBy: ["createdAt"],
      sortDesc: [true],
      total: 0,
    };
  },
  getters: {
    getDetailedBanner(state: BannerState): Banner | null {
      return state.detailedBanner;
    },

    getBanners(state: BannerState): Banner[] {
      return state.bannerIds.map((bannerId: string | null) => {
        return state.banners.get(bannerId) as Banner;
      });
    },

    getBannerById:
      (state: BannerState) =>
      (id: string): Banner | undefined => {
        return state.banners.get(id);
      },

    getLimit(state: BannerState): number {
      return state.limit;
    },

    getOffset(state: BannerState): number {
      return state.offset;
    },

    getPage(state: BannerState): number {
      return state.page;
    },

    getSortBy(state: BannerState): string[] {
      return state.sortBy;
    },

    getSortDesc(state: BannerState): boolean[] {
      return state.sortDesc;
    },

    getSortDir(state: BannerState): string[] {
      return state.sortDesc.map((x: boolean) => {
        return x ? "desc" : "asc";
      });
    },
  },
  actions: {
    create(properties: BannerProperties): Promise<Response<BannerPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        BannerApi.create(properties)
          .then((resp: Response<BannerPayload>) => {
            if (!resp.payload || !resp.payload.banner) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.banners.set(resp.payload.banner.id, resp.payload.banner);
            this.bannerIds.push(resp.payload.banner.id);
            resolve(resp);
          })
          .catch((resp: Response<BannerPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    delete(id: string): Promise<Response<BannerPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        BannerApi.delete(id)
          .then((resp: Response<BannerPayload>) => {
            if (!resp.payload || !resp.payload.banner) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.bannerIds = this.bannerIds.filter(
              (x) => x !== resp?.payload?.banner?.id
            );
            this.banners.delete(resp.payload.banner.id);
            resolve(resp);
          })
          .catch((resp: Response<BannerPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    fetchAll(
      queryContext: FetchBannersQueryContext
    ): Promise<Response<BannerPagePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        BannerApi.all(queryContext)
          .then((resp: Response<BannerPagePayload>) => {
            if (
              !resp.payload ||
              !resp.payload.banners ||
              !resp.payload.pagination
            ) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.total = resp.payload.pagination.total;

            this.bannerIds = resp.payload.banners.map((n: Banner) => n.id);
            const updatedBanners = resp.payload.banners.reduce(
              (acc: Map<string, Banner>, n: Banner) => {
                if (!n.id) {
                  return acc;
                }
                acc.set(n.id, n);
                return acc;
              },
              new Map<string, Banner>()
            );

            this.banners = updatedBanners;
            resolve(resp);
          })
          .catch((resp: Response<BannerPagePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    fetchById(id: string): Promise<Response<BannerPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        this.detailedBanner = null;

        BannerApi.getById(id)
          .then((resp: Response<BannerPayload>) => {
            if (!resp.payload || !resp.payload.banner) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.detailedBanner = resp.payload.banner;
            resolve(resp);
          })
          .catch((resp: Response<BannerPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    setPagination(options: Options): Promise<void> {
      return new Promise((resolve, _reject) => {
        this.limit = options.itemsPerPage;
        this.offset = (options.page - 1) * options.itemsPerPage;
        this.page = options.page;
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
        resolve();
      });
    },

    update(params: UpdateBannerParameters): Promise<Response<BannerPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        BannerApi.update(params.id, params.properties)
          .then((resp: Response<BannerPayload>) => {
            if (!resp.payload || !resp.payload.banner) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.banners.set(resp.payload.banner.id, resp.payload.banner);
            resolve(resp);
          })
          .catch((resp: Response<BannerPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
  },
});
