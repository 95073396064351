import StatusMessage from "./statusMessage";

export default class Status {
  messages: StatusMessage[];

  constructor(json: Record<string, unknown>) {
    if (!json.messages) {
      this.messages = [];
      return;
    }

    this.messages = (json.messages as Record<string, unknown>[]).map(
      (x: Record<string, unknown>) => {
        return new StatusMessage(x);
      }
    );
  }
}
