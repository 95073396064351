import NotificationPayload from "../../models/notificationPayload";
import NotificationProperties from "../../models/notificationProperties";
import { defineStore } from "pinia";
import { FetchNotificationsQueryContext } from "../../api/dto/fetchNotificationsQueryContext";
import Response from "../../models/response";
import Notification from "../../models/notification";
import NotificationApi from "@/api/notification";
import NotificationPagePayload from "@/models/notificationPagePayload";

interface NotificationState {
  detailedNotification: Notification | null;
  isLoading: boolean;
  limit: number;
  notificationIds: (string | null)[];
  notifications: Map<string | null, Notification>;
  offset: number;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
  total: number | null;
}

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}

interface UpdateNotificationParameters {
  id: string;
  properties: NotificationProperties;
}

export const useNotificationStore = defineStore({
  id: "notification",
  state: (): NotificationState => {
    return {
      detailedNotification: null,
      isLoading: false,
      limit: 10,
      notificationIds: [],
      notifications: new Map<string, Notification>(),
      page: 1,
      offset: 0,
      sortBy: ["createdAt"],
      sortDesc: [true],
      total: 0,
    };
  },
  getters: {
    getDetailedNotification(state: NotificationState): Notification | null {
      return state.detailedNotification;
    },

    getNotifications(state: NotificationState): Notification[] {
      return state.notificationIds.map((notificationId: string | null) => {
        return state.notifications.get(notificationId) as Notification;
      });
    },

    getNotificationById:
      (state: NotificationState) =>
      (id: string): Notification | undefined => {
        return state.notifications.get(id);
      },

    getLimit(state: NotificationState): number {
      return state.limit;
    },

    getOffset(state: NotificationState): number {
      return state.offset;
    },

    getPage(state: NotificationState): number {
      return state.page;
    },

    getSortBy(state: NotificationState): string[] {
      return state.sortBy;
    },

    getSortDesc(state: NotificationState): boolean[] {
      return state.sortDesc;
    },

    getSortDir(state: NotificationState): string[] {
      return state.sortDesc.map((x: boolean) => {
        return x ? "desc" : "asc";
      });
    },
  },
  actions: {
    approve(id: string): Promise<Response<NotificationPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        NotificationApi.approve(id)
          .then((resp: Response<NotificationPayload>) => {
            if (!resp.payload || !resp.payload.notification) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = true;
            resolve(resp);
          })
          .catch((resp: Response<NotificationPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
    create(
      properties: NotificationProperties
    ): Promise<Response<NotificationPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        NotificationApi.create(properties)
          .then((resp: Response<NotificationPayload>) => {
            if (!resp.payload || !resp.payload.notification) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = true;
            resolve(resp);
          })
          .catch((resp: Response<NotificationPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
    delete(id: string): Promise<Response<NotificationPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        NotificationApi.delete(id)
          .then((resp: Response<NotificationPayload>) => {
            if (!resp.payload || !resp.payload.notification) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            resolve(resp);
          })
          .catch((resp: Response<NotificationPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
    fetchAll(
      queryContext: FetchNotificationsQueryContext
    ): Promise<Response<NotificationPagePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        NotificationApi.all(queryContext)
          .then((resp: Response<NotificationPagePayload>) => {
            if (
              !resp.payload ||
              !resp.payload.notifications ||
              !resp.payload.pagination
            ) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.total = resp.payload.pagination.total;
            this.notificationIds = resp.payload.notifications.map(
              (n: Notification) => n.id
            );
            const updatedNotifications = resp.payload.notifications.reduce(
              (acc: Map<string, Notification>, n: Notification) => {
                if (!n.id) {
                  return acc;
                }
                acc.set(n.id, n);
                return acc;
              },
              new Map<string, Notification>()
            );

            this.notifications = updatedNotifications;

            resolve(resp);
          })
          .catch((resp: Response<NotificationPagePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
    fetchById(id: string): Promise<Response<NotificationPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        this.detailedNotification = null;

        NotificationApi.getById(id)
          .then((resp: Response<NotificationPayload>) => {
            if (!resp.payload || !resp.payload.notification) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.detailedNotification = resp.payload.notification;
            resolve(resp);
          })
          .catch((resp: Response<NotificationPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
    setPagination(options: Options): Promise<void> {
      return new Promise((resolve, _reject) => {
        this.limit = options.itemsPerPage;
        this.offset = (options.page - 1) * options.itemsPerPage;
        this.page = options.page;
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
        resolve();
      });
    },

    update(
      params: UpdateNotificationParameters
    ): Promise<Response<NotificationPayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        NotificationApi.update(params.id, params.properties)
          .then((resp: Response<NotificationPayload>) => {
            if (!resp.payload || !resp.payload.notification) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            let notificationId = "";
            if (resp.payload.notification.id) {
              notificationId = resp.payload.notification.id;
            }
            this.notifications.set(notificationId, resp.payload.notification);
            this.isLoading = false;
            resolve(resp);
          })
          .catch((resp: Response<NotificationPayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },
  },
});
