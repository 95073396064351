import BannerStatus from "./bannerStatus";
import Brand from "./brand";
import DeviceType from "./deviceType";
import Language from "./language";
import Region from "./region";
import User from "./user";

export default class Banner {
  appVersions: string[];
  brands: Brand[];
  createdAt: string | null;
  createdBy: User | null;
  customerGuids: string[];
  deviceTypes: DeviceType[];
  deviceVersions: string[];
  endsAt: string | null;
  feature: string | null;
  generations: string[];
  id: string | null;
  language: Language | null;
  messageBody: string | null;
  messageTitle: string | null;
  modelCodes: string[];
  modelYears: string[];
  name: string | null;
  notes: string | null;
  referenceNumber: string | null;
  regions: Region[];
  startsAt: string | null;
  status: BannerStatus;
  subscriptions: string[];
  updatedAt: string | null;
  vins: string[];

  constructor() {
    this.appVersions = [];
    this.brands = [];
    this.createdAt = null;
    this.createdBy = null;
    this.customerGuids = [];
    this.deviceTypes = [];
    this.deviceVersions = [];
    this.endsAt = null;
    this.feature = null;
    this.generations = [];
    this.id = null;
    this.language = Language.ENGLISH;
    this.messageBody = null;
    this.messageTitle = null;
    this.modelCodes = [];
    this.modelYears = [];
    this.name = null;
    this.notes = null;
    this.referenceNumber = null;
    this.regions = [];
    this.startsAt = null;
    this.status = BannerStatus.UNPUBLISHED;
    this.subscriptions = [];
    this.updatedAt = null;
    this.vins = [];
  }

  static fromJSON(json: Record<string, unknown>): Banner {
    const banner = new Banner();

    banner.appVersions = (json.appVersions as string[]) ?? [];
    banner.brands = (json.brands as Brand[]) ?? null;
    banner.createdAt = (json.createdAt as string) ?? null;
    banner.createdBy = User.fromJSON(json.createdBy as Record<string, unknown>);
    banner.customerGuids = (json.customerGuids as string[]) ?? [];
    banner.deviceTypes = (json.deviceTypes as DeviceType[]) ?? [];
    banner.deviceVersions = (json.deviceVersions as string[]) ?? [];
    banner.endsAt = (json.endsAt as string) ?? null;
    banner.feature = (json.feature as string) ?? null;
    banner.generations = (json.generations as string[]) ?? [];
    banner.id = json.id as string;
    banner.language = (json.language as Language) ?? null;
    banner.messageBody = (json.messageBody as string) ?? null;
    banner.messageTitle = (json.messageTitle as string) ?? null;
    banner.modelCodes = (json.modelCodes as string[]) ?? [];
    banner.modelYears = (json.modelYears as string[]) ?? [];
    banner.name = (json.name as string) ?? null;
    banner.notes = (json.notes as string) ?? null;
    banner.referenceNumber = (json.referenceNumber as string) ?? null;
    banner.regions = (json.regions as Region[]) ?? [];
    banner.startsAt = (json.startsAt as string) ?? null;
    banner.status = (json.status as BannerStatus) ?? BannerStatus.UNPUBLISHED;
    banner.subscriptions = (json.subscriptions as string[]) ?? [];
    banner.updatedAt = (json.updatedAt as string) ?? null;
    banner.vins = (json.vins as string[]) ?? [];

    return banner;
  }
}
