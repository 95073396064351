

































































import { defineComponent } from "@vue/composition-api";
import { useAuthStore } from "@/store/auth";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "AppHeader",
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    return {
      authStore,
      userStore,
    };
  },
  computed: {
    headerHeight(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "60px";
        case "sm":
          return "60px";
        case "md":
          return "68px";
        case "lg":
          return "68px";
        case "xl":
          return "68px";
        default:
          return "68px";
      }
    },
    themeIcon(): string {
      return this.$vuetify.theme.dark ? "wb_sunny" : "bedtime";
    },
  },
  mounted() {
    this.userStore.fetchProfile();
  },
  methods: {
    iconSrc(iconName: string): string {
      if (this.$vuetify.theme.dark) {
        return require(`../assets/icons/${iconName}-w.png`);
      } else {
        return require(`../assets/icons/${iconName}.png`);
      }
    },
    async requestLogout() {
      await this.authStore.logout();
      if (process.env.VUE_APP_COGNITO) {
        this.$router.push("/login");
      } else {
        window.location.href = process.env.VUE_APP_ADMIN_PORTAL ?? "/login";
      }
    },
    toggleDarkTheme(): void {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkMode", `${this.$vuetify.theme.dark}`);
    },
  },
});
