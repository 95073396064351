var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.templates,"loading":_vm.isLoading,"multi-sort":true,"options":_vm.tableOptions,"server-items-length":_vm.totalTemplates,"no-data-text":"No templates found."},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.didSelectRow},scopedSlots:_vm._u([{key:"header.referenceNumber",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.referenceNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.referenceNumber))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"header.messageBody",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.messageBody",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{model:{value:(item.messageBody),callback:function ($$v) {_vm.$set(item, "messageBody", $$v)},expression:"item.messageBody"}})]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.createdBy)?_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary white--text","size":"36"}},'v-avatar',attrs,false),on),[_c('strong',[_vm._v(_vm._s(_vm.initials(item.createdBy)))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltip(item.createdBy))+" ")])]):_vm._e(),_c('NullableField',{attrs:{"type":"datetime"},model:{value:(item.createdAt),callback:function ($$v) {_vm.$set(item, "createdAt", $$v)},expression:"item.createdAt"}})]}},{key:"header.language",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('NullableField',{model:{value:(item.language),callback:function ($$v) {_vm.$set(item, "language", $$v)},expression:"item.language"}})]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"header-grey"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }