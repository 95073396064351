import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Banners from "../views/Banners.vue";
import Notifications from "../views/Notifications.vue";
import Templates from "../views/Templates.vue";
import Login from "../views/Login.vue";

import { ACCESS_TOKEN } from "../utils/constant";

Vue.use(VueRouter);

const hasToken = () => {
  return !!localStorage.getItem(ACCESS_TOKEN);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const ifAuthenticated = (to: Route, from: Route, next: Function) => {
  if (hasToken()) {
    next();
    return;
  }

  const redirectTo = window.location.pathname;
  if (redirectTo === "/") {
    next({ name: "Login" });
  } else {
    next({ name: "Login", query: { from: redirectTo } });
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/banners",
    name: "Banners",
    component: Banners,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/callback",
    name: "AuthCallback",
    component: () =>
      import(/* webpackChunkName: "callback" */ "../views/AuthCallback.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
