import { defineStore } from "pinia";
import CustomerPagePayload from "../../models/customerPagePayload";
import Response from "../../models/response";
import { FetchCustomersQueryContext } from "../../api/dto/fetchCustomersQueryContext";
import Customer from "../../models/customer";
import CustomerApi from "@/api/customer";

interface CustomerState {
  isLoading: boolean;
  limit: number;
  customerIds: (string | null)[];
  customers: Map<string | null, Customer>;
  offset: number;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
  total: number;
}

interface Options {
  sortDesc: boolean[];
  page: number;
  itemsPerPage: number;
  sortBy: string[];
}

export const useCustomerStore = defineStore({
  id: "customer",
  state: (): CustomerState => {
    return {
      isLoading: false,
      limit: 10,
      customerIds: [],
      customers: new Map<string, Customer>(),
      page: 1,
      offset: 0,
      sortBy: ["createdAt"],
      sortDesc: [true],
      total: 0,
    };
  },
  getters: {
    getLimit(state: CustomerState): number {
      return state.limit;
    },

    getOffset(state: CustomerState): number {
      return state.offset;
    },

    getPage(state: CustomerState): number {
      return state.page;
    },

    getSortBy(state: CustomerState): string[] {
      return state.sortBy;
    },

    getSortDesc(state: CustomerState): boolean[] {
      return state.sortDesc;
    },

    getSortDir(state: CustomerState): string[] {
      return state.sortDesc.map((x: boolean) => {
        return x ? "desc" : "asc";
      });
    },
  },
  actions: {
    fetchAll(
      queryContext: FetchCustomersQueryContext
    ): Promise<Response<CustomerPagePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        CustomerApi.all(queryContext)
          .then((resp: Response<CustomerPagePayload>) => {
            if (
              !resp.payload ||
              !resp.payload.customers ||
              !resp.payload.pagination
            ) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.total = resp.payload.pagination.total
              ? resp.payload.pagination.total
              : 0;

            this.customerIds = resp.payload.customers.map(
              (n: Customer) => n.guid
            );
            const updatedCustomers = resp.payload.customers.reduce(
              (acc: Map<string, Customer>, n: Customer) => {
                if (!n.guid) {
                  return acc;
                }
                acc.set(n.guid, n);
                return acc;
              },
              new Map<string, Customer>()
            );

            this.customers = updatedCustomers;
            resolve(resp);
          })
          .catch((resp: Response<CustomerPagePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    search(
      queryContext: FetchCustomersQueryContext
    ): Promise<Response<CustomerPagePayload>> {
      return new Promise((resolve, reject) => {
        this.isLoading = true;

        CustomerApi.search(queryContext)
          .then((resp: Response<CustomerPagePayload>) => {
            if (
              !resp.payload ||
              !resp.payload.customers ||
              !resp.payload.pagination
            ) {
              this.isLoading = false;
              reject(resp);
              return;
            }

            this.isLoading = false;
            this.total = resp.payload.pagination.total
              ? resp.payload.pagination.total
              : 0;

            this.customerIds = resp.payload.customers.map(
              (n: Customer) => n.guid
            );
            const updatedCustomers = resp.payload.customers.reduce(
              (acc: Map<string, Customer>, n: Customer) => {
                if (!n.guid) {
                  return acc;
                }
                acc.set(n.guid, n);
                return acc;
              },
              new Map<string, Customer>()
            );

            this.customers = updatedCustomers;
            resolve(resp);
          })
          .catch((resp: Response<CustomerPagePayload>) => {
            this.isLoading = false;
            reject(resp);
          });
      });
    },

    setPagination(options: Options): Promise<void> {
      return new Promise((resolve, _reject) => {
        this.limit = options.itemsPerPage;
        this.offset = (options.page - 1) * options.itemsPerPage;
        this.page = options.page;
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
        resolve();
      });
    },
  },
});
