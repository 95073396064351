import axios from "axios";
import qs from "querystring";
import { OPENID_SCOPE } from "../utils/constant";

export interface AuthResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token?: string;
}

export const signIn = (codeChallenge: string): void => {
  const redirect_uri = window.location.origin + "/callback";

  const query = qs.stringify({
    client_id: process.env.VUE_APP_CLIENT_ID,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    identity_provider: "COGNITO",
    redirect_uri,
    response_type: "code",
    scope: OPENID_SCOPE,
    state: process.env.VUE_APP_CLIENT_STATE,
  });

  const authURL = `${process.env.VUE_APP_ISSUER_URL}/oauth2/authorize?${query}`;
  window.location.assign(authURL);
};

export const getTokenFromAuthCode = async (
  authCode: string,
  codeVerifier: string
): Promise<AuthResponse> => {
  const body = qs.stringify({
    client_id: process.env.VUE_APP_CLIENT_ID,
    code: authCode,
    code_verifier: codeVerifier,
    grant_type: "authorization_code",
    redirect_uri: window.location.origin + "/callback",
  });

  const { data } = await axios.post<AuthResponse>(
    `${process.env.VUE_APP_ISSUER_URL}/oauth2/token`,
    body,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return data;
};

export const refreshTokens = async (
  refreshToken: string
): Promise<AuthResponse> => {
  const body = qs.stringify({
    client_id: process.env.VUE_APP_CLIENT_ID,
    grant_type: "refresh_token",
    redirect_uri: window.location.origin + "/callback",
    refresh_token: refreshToken,
  });

  const { data } = await axios.post<AuthResponse>(
    `${process.env.VUE_APP_ISSUER_URL}/access_token`,
    body,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  return data;
};

export const getUserInfo = async (
  access_token: string
): Promise<AuthResponse> => {
  const { data } = await axios.get(
    `${process.env.VUE_APP_ISSUER_URL}/oauth2/userInfo`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );
  return data;
};
