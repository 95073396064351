import Status from "./status";

export default class Response<T> {
  payload?: T;
  status?: Status;

  constructor(json: Record<string, unknown>) {
    this.payload = json.payload ? (json.payload as T) : undefined;
    this.status = json.status
      ? new Status(json.status as Record<string, unknown>)
      : undefined;
  }
}
