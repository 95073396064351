import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "../scss/typography.scss";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        "approval-message-grey": "f2f2f2",
        background: "#fff",
        blue: "#0770a4",
        "dialog-grey": "#fafafa",
        green: "#00c12a",
        icon: "#000",
        navbar: "#fff",
        primary: "#eb0a1e",
      },
      dark: {
        "approval-message-grey": "f2f2f2",
        background: "#2a2a2a",
        blue: "#00d2ff",
        "dialog-grey": "#2a2a2a",
        green: "#00c12a",
        icon: "#fff",
        navbar: "#1e1e1e",
        primary: "#eb0a1e",
      },
    },
  },
});
