import App from "./App.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { datadogRum } from "@datadog/browser-rum";
import { Datetime } from "vue-datetime";
import Notifications from "vue-notification";
import router from "./router";
import { version } from "../package.json";
import "vue-datetime/dist/vue-datetime.css";
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import vuetify from "./plugins/vuetify";

import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { ZoneContextManager } from "@opentelemetry/context-zone-peer-dep";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";

if (process.env.VUE_APP_TELEMETRY == "OTLP") {
  const exporter = new OTLPTraceExporter({});
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: "vector-portal",
    }),
  });
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  provider.register({
    contextManager: new ZoneContextManager(),
  });
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        // load custom configuration for xml-http-request instrumentation
        "@opentelemetry/instrumentation-xml-http-request": {
          propagateTraceHeaderCorsUrls: [/.+/g],
        },
        // load custom configuration for fetch instrumentation
        "@opentelemetry/instrumentation-fetch": {
          propagateTraceHeaderCorsUrls: [/.+/g],
        },
      }),
    ],
  });
} else if (process.env.VUE_APP_TELEMETRY == "DD") {
  datadogRum.init({
    applicationId: "4ea23ad6-b627-475e-a4fd-90808168c81a",
    clientToken: "pub209c2f0b847d7d6d4851d727a5168cae",
    site: "datadoghq.com",
    service: "vector-portal",
    env: process.env.VUE_APP_DD_ENV,
    allowedTracingOrigins: [process.env.VUE_APP_API_URL!],
    version: version,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

Vue.component("datetime", Datetime);
Vue.config.productionTip = false;

export const pinia = createPinia();
Vue.use(Notifications);
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionApi);

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
