export enum Role {
  ADMIN = "ADMIN",
  APPROVER = "APPROVER",
  USER = "USER",
}

export default class User {
  email: string | null;
  name: string | null;
  roles: Role[];

  constructor() {
    this.email = null;
    this.name = null;
    this.roles = [];
  }

  static fromJSON(json: Record<string, unknown>): User {
    const user = new User();

    user.email = json.email as string;
    user.name = json.name as string;
    user.roles = (json.roles as Role[]) ?? [];

    return user;
  }

  static fromLocalStorageJSON(json: Record<string, unknown>): User {
    const user = new User();

    user.email = json.email as string;
    user.name = json.name as string;
    user.roles = (json.roles as Role[]) ?? [];

    return user;
  }
}
