import { AxiosError } from "axios";
import Response from "../models/response";
import { ACCESS_TOKEN } from "@/utils/constant";
import { v4 as uuid } from "uuid";

export default class Util {
  static authHeaders(): Record<string, string> {
    return {
      Authorization: `${Util.authHeader(localStorage.getItem(ACCESS_TOKEN))}`,
      "Cache-Control": "no-cache",
      Expires: "0",
      Pragma: "no-cache",
      "User-email": `${Util.emailFromJWT(localStorage.getItem(ACCESS_TOKEN))}`,
      "X-channel": "UI",
      "X-Api-Key": `${process.env.VUE_APP_SVPP_API_KEY}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CORRELATIONID": `${uuid()}`,
    };
  }

  static apiUrl(path: string): string {
    return `${process.env.VUE_APP_API_URL}${path}`;
  }

  static handleError<T>(error: AxiosError): Promise<Response<T>> {
    if (error.response) {
      return Promise.reject(error.response.data as Response<T>);
    }
    return Promise.reject(new Response({}));
  }

  static handleResponse<T>(response: Response<T>): Promise<Response<T>> {
    if (!response.status || response.status.messages.length === 0) {
      return Promise.reject(response);
    }

    return response.status.messages[0].responseCode == "V-200"
      ? Promise.resolve(response)
      : Promise.reject(response);
  }

  static emailFromJWT(token: string | null): string {
    if (token == null) {
      return "";
    }
    try {
      const email = JSON.parse(atob(token.split(".")[1]))[
        "preferred_username"
      ].toLowerCase();
      return email;
    } catch (e) {
      return "";
    }
  }

  static authHeader(token: string | null): string {
    if (process.env.VUE_APP_COGNITO) {
      return `${token}`;
    } else {
      return `Bearer ${token}`;
    }
  }

  static isUnauthorized(error: AxiosError): boolean {
    // 403 is added as a unauthorized response code due to
    // the SVPP Api Gateway responding with a 403 instead of a 401
    if (!error.response) {
      return false;
    }
    const unauthorizedRespCodes = [401, 403];
    return unauthorizedRespCodes.includes(error.response.status);
  }
}
